import * as React from 'react'
import styled from 'styled-components'
import { SEO } from '../../components/seo'

const StyledTable = styled.table`
  width: 100%;
  color: var(--color-navy);
  margin: 32px 0;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid var(--color-navy);
  }

  th,
  td {
    text-align: center;

    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      width: 10%;
    }

    &:nth-child(7) {
      width: 40%;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(7) {
      text-align: left;
    }
  }
  th {
    padding: 16px 0;
    font-weight: 400;
    font-family: var(--font-sans);
    font-size: calc((9 / 16) * 1rem);
    letter-spacing: 0.04em;
  }
  td {
    padding: 32px 0;
  }
`

type Props = {}

export default class StyleguidePage extends React.Component<Props, {}> {
  public render() {
    return (
      <>
        <SEO title="Type styles" />
        <div
          className="container container--grid"
          style={{ maxWidth: 'none', paddingTop: 72 }}
        >
          <div className="container__row">
            <h1 className="type-d-sp-xxl">Desktop Type Scale</h1>
            <Typebeast title="Headline Styles">
              <tr>
                <td className="type-d-h-xxl">XXL</td>
                <td>Heldane Display</td>
                <td>400</td>
                <td>95px</td>
                <td>-0.01em</td>
                <td>93px</td>
                <td className="type-d-h-xxl">
                  A wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-d-h-xl">XL</td>
                <td>Heldane Display</td>
                <td>400</td>
                <td>61px</td>
                <td>-0.01em</td>
                <td>64px</td>
                <td className="type-d-h-xl">
                  A wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-d-h1">h1</td>
                <td>Heldane Display</td>
                <td>400/700</td>
                <td>55px</td>
                <td>-0.01em</td>
                <td>61px</td>
                <td className="type-d-h1">
                  A wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-d-h2">h2</td>
                <td>Heldane Display</td>
                <td>400</td>
                <td>43px</td>
                <td>—</td>
                <td>48px</td>
                <td className="type-d-h2">
                  Jackie will budget for the most expensive zoology equipment.
                </td>
              </tr>
              <tr>
                <td className="type-d-h3">h3</td>
                <td>Heldane Display</td>
                <td>500</td>
                <td>35px</td>
                <td>—</td>
                <td>41px</td>
                <td className="type-d-h3">
                  Jackie will budget for the most expensive zoology equipment.
                </td>
              </tr>
              <tr>
                <td className="type-d-h4">h4</td>
                <td>Heldane Display</td>
                <td>500</td>
                <td>31px</td>
                <td>—</td>
                <td>37px</td>
                <td className="type-d-h4">
                  Jackie will budget for the most expensive zoology equipment.
                </td>
              </tr>
              <tr>
                <td className="type-d-h5">h5</td>
                <td>Heldane Display</td>
                <td>400/500</td>
                <td>23px</td>
                <td>—</td>
                <td>27px</td>
                <td className="type-d-h5">
                  Jackie will budget for the most expensive zoology equipment.
                </td>
              </tr>
              <tr>
                <td className="type-d-h6">h6</td>
                <td>Heldane Display</td>
                <td>500</td>
                <td>20px</td>
                <td>—</td>
                <td>24px</td>
                <td className="type-d-h6">
                  Jackie will budget for the most expensive zoology equipment.
                </td>
              </tr>
            </Typebeast>
            <Typebeast title="Paragraph Styles">
              <tr>
                <td className="type-d-p-xl">P-XL</td>
                <td>Favorit</td>
                <td>400</td>
                <td>34px</td>
                <td>-0.02em</td>
                <td>38px</td>
                <td className="type-d-p-xl">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-d-p-lg">P-LG</td>
                <td>Favorit</td>
                <td>400</td>
                <td>18px</td>
                <td>—</td>
                <td>29px</td>
                <td className="type-d-p-lg">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-d-p-md">P-MD</td>
                <td>Favorit</td>
                <td>400</td>
                <td>16px</td>
                <td>—</td>
                <td>26px</td>
                <td className="type-d-p-md">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-d-p-sm">P-SM</td>
                <td>Favorit</td>
                <td>400</td>
                <td>14px</td>
                <td>—</td>
                <td>23px</td>
                <td className="type-d-p-sm">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
            </Typebeast>
            <Typebeast title="Utility Styles">
              <tr>
                <td className="type-d-nav-menu-lg">Nav Menu LG</td>
                <td>Favorit</td>
                <td>400</td>
                <td>34px</td>
                <td>0.01em</td>
                <td>36px</td>
                <td className="type-d-nav-menu-lg">
                  Jackie will budget for the most expensive zoology equipment
                </td>
              </tr>
              <tr>
                <td className="type-d-nav-menu">Nav Menu Regular</td>
                <td>Favorit</td>
                <td>400</td>
                <td>23px</td>
                <td>0.01em</td>
                <td>23px</td>
                <td className="type-d-nav-menu">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-d-label-lg">Label LG</td>
                <td>Favorit</td>
                <td>400</td>
                <td>14px</td>
                <td>0.04em</td>
                <td>14px</td>
                <td className="type-d-label-lg">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-d-footer-link">Footer Link</td>
                <td>Favorit</td>
                <td>400</td>
                <td>14px</td>
                <td>0.04em</td>
                <td>26px</td>
                <td className="type-d-footer-link">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-d-label-md">Label MD</td>
                <td>Favorit</td>
                <td>400</td>
                <td>12px</td>
                <td>0.04em</td>
                <td>12px</td>
                <td className="type-d-label-md">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-d-nav-label">Nav Label / Link</td>
                <td>Favorit</td>
                <td>500</td>
                <td>12px</td>
                <td>0.04em</td>
                <td>12px</td>
                <td className="type-d-nav-label">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-d-label-sm-mono">Label SM Mono</td>
                <td>Favorit Mono</td>
                <td>400</td>
                <td>10px</td>
                <td>—</td>
                <td>10px</td>
                <td className="type-d-label-sm-mono">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-d-label-md-mono">Label MD Mono</td>
                <td>Favorit Mono</td>
                <td>500</td>
                <td>12px</td>
                <td>0.02em</td>
                <td>12px</td>
                <td className="type-d-label-md-mono">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-d-label-lg-mono">Label LG Mono</td>
                <td>Favorit Mono</td>
                <td>500</td>
                <td>14px</td>
                <td>0.08em</td>
                <td>14px</td>
                <td className="type-d-label-lg-mono">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
            </Typebeast>

            <Typebeast title="Special Styles">
              <tr>
                <td className="type-d-sp-xxl">SP XXL</td>
                <td>Formula Condensed</td>
                <td>700</td>
                <td>140px</td>
                <td>0.01em</td>
                <td>126px</td>
                <td className="type-d-sp-xxl">
                  A wizard’s job is to vex chumps quickly
                </td>
              </tr>
              <tr>
                <td className="type-d-cta-lg">CTA LG</td>
                <td>Favorit</td>
                <td>400</td>
                <td>100px</td>
                <td>-0.02em</td>
                <td>94px</td>
                <td className="type-d-cta-lg">
                  A wizard’s job is to vex chumps quickly
                </td>
              </tr>
            </Typebeast>

            <h1 className="type-d-sp-xxl">Mobile Type Scale</h1>
            <Typebeast title="Headline Styles">
              <tr>
                <td className="type-m-xl">XL</td>
                <td>Heldane Display</td>
                <td>400</td>
                <td>43px</td>
                <td>-0.01em</td>
                <td>42px</td>
                <td className="type-m-xl">
                  A wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-m-h1">h1</td>
                <td>Heldane Display</td>
                <td>400/700</td>
                <td>39px</td>
                <td>-0.01em</td>
                <td>38px</td>
                <td className="type-m-h1">
                  A wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-m-h2">h2</td>
                <td>Heldane Display</td>
                <td>400</td>
                <td>24px</td>
                <td>—</td>
                <td>28px</td>
                <td className="type-m-h2">
                  Jackie will budget for the most expensive zoology equipment.
                </td>
              </tr>
              <tr>
                <td className="type-m-h3">h3</td>
                <td>Heldane Display</td>
                <td>500</td>
                <td>20px</td>
                <td>—</td>
                <td>24px</td>
                <td className="type-m-h3">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-m-h4">h4</td>
                <td>Heldane Display</td>
                <td>500</td>
                <td>16px</td>
                <td>—</td>
                <td>19px</td>
                <td className="type-m-h4">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-m-h5">h5</td>
                <td>Heldane Display</td>
                <td>500</td>
                <td>16px</td>
                <td>—</td>
                <td>19px</td>
                <td className="type-m-h5">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-m-h6">h6</td>
                <td>Heldane Display</td>
                <td>500</td>
                <td>16px</td>
                <td>—</td>
                <td>19px</td>
                <td className="type-m-h6">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
            </Typebeast>

            <Typebeast title="Paragraph Styles">
              <tr>
                <td className="type-m-p-lg">P-LG</td>
                <td>Favorit</td>
                <td>400</td>
                <td>24px</td>
                <td>—</td>
                <td>27px</td>
                <td className="type-m-p-lg">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-m-p-md">P-MD</td>
                <td>Favorit</td>
                <td>400</td>
                <td>14px</td>
                <td>—</td>
                <td>23px</td>
                <td className="type-m-p-md">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-m-p-sm">P-SM</td>
                <td>Favorit</td>
                <td>400</td>
                <td>12px</td>
                <td>—</td>
                <td>19px</td>
                <td className="type-m-p-sm">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
            </Typebeast>

            <Typebeast title="Utility Styles">
              <tr>
                <td className="type-m-nav-menu-lg">Nav Menu LG</td>
                <td>Favorit</td>
                <td>400</td>
                <td>34px</td>
                <td>0.01em</td>
                <td>36px</td>
                <td className="type-m-nav-menu-lg">
                  Jackie will budget for the most expensive zoology equipment
                </td>
              </tr>
              <tr>
                <td className="type-m-nav-menu">Nav Menu Regular</td>
                <td>Favorit</td>
                <td>400</td>
                <td>23px</td>
                <td>0.01em</td>
                <td>23px</td>
                <td className="type-m-nav-menu">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog.
                </td>
              </tr>
              <tr>
                <td className="type-m-footer-link">Footer Link</td>
                <td>Favorit</td>
                <td>400</td>
                <td>14px</td>
                <td>0.04em</td>
                <td>26px</td>
                <td className="type-m-footer-link">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-m-nav-label">Nav Label / Link</td>
                <td>Favorit</td>
                <td>500</td>
                <td>12px</td>
                <td>0.04em</td>
                <td>12px</td>
                <td className="type-m-nav-label">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
              <tr>
                <td className="type-m-label-sm">Label SM</td>
                <td>Favorit</td>
                <td>400</td>
                <td>10px</td>
                <td>0.02em</td>
                <td>10px</td>
                <td className="type-m-label-sm">
                  October 9, 2019 | Ben Blumenfeld
                </td>
              </tr>
              <tr>
                <td className="type-m-label-sm-mono">Label SM Mono</td>
                <td>Favorit Mono</td>
                <td>400</td>
                <td>10px</td>
                <td>—</td>
                <td>10px</td>
                <td className="type-m-label-sm-mono">
                  Jackie will budget for the most expensive zoology equipment. A
                  wizard’s job is to vex chumps quickly in fog. Zelda might fix
                  the job growth plans very quickly on Monday.
                </td>
              </tr>
            </Typebeast>

            <Typebeast title="Special Styles">
              <tr>
                <td className="type-m-sp-xxl">SP XXL</td>
                <td>Formula Condensed</td>
                <td>700</td>
                <td>49px</td>
                <td>0.01em</td>
                <td>42px</td>
                <td className="type-m-sp-xxl">Resources</td>
              </tr>
              <tr>
                <td className="type-m-sp-xl">SP xl</td>
                <td>Formula Condensed</td>
                <td>700</td>
                <td>36px</td>
                <td>0.01em</td>
                <td>31px</td>
                <td className="type-m-sp-xl">Resources</td>
              </tr>
              <tr>
                <td className="type-m-cta-lg">CTA LG</td>
                <td>Favorit</td>
                <td>400</td>
                <td>100px</td>
                <td>-0.02em</td>
                <td>94px</td>
                <td className="type-m-cta-lg">Enter email address</td>
              </tr>
            </Typebeast>
          </div>
        </div>
      </>
    )
  }
}

function Typebeast({ title, children }: any) {
  return (
    <section style={{ margin: '72px 0' }}>
      <h2 style={{ marginBottom: 36 }} className="type-d-nav-menu-lg">
        {title}
      </h2>
      <StyledTable>
        <thead>
          <tr>
            <th>Typestyle</th>
            <th>Typeface</th>
            <th>Weight</th>
            <th>Size</th>
            <th>Character</th>
            <th>Line-height</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </StyledTable>
    </section>
  )
}
